@font-face {
  font-family: 'Museo Sans';
  src: url('/assets/fonts/MuseoSans-100.otf');
  font-weight: 100;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('/assets/fonts/MuseoSans-100Italic.otf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('/assets/fonts/MuseoSans-300.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('/assets/fonts/MuseoSans-300Italic.otf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('/assets/fonts/MuseoSans_500.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('/assets/fonts/MuseoSans_500_Italic.otf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('/assets/fonts/MuseoSans_700.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('/assets/fonts/MuseoSans-700Italic.otf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('/assets/fonts/MuseoSans_900.otf');
  font-weight: 900;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('/assets/fonts/MuseoSans-900Italic.otf');
  font-weight: 900;
  font-style: italic;
}
