body.realestate {
  .fun-fact-area:before {
    background: orange;
  }
  .widget-item .widget-title {
    color: orange;
  }
  footer {
  }
}

a, .btn-link {
  color: #00a3da;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: #00a3da;
}
