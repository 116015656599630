/*====================
07. Slider Style CSS
=======================*/

@keyframes fadeInRightTitle {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUpTitle {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.slider-area-wrapper {
  color: $white;
}

.tp-caption.Button-Outline-Secondary,
.Button-Outline-Secondary {
  color: $white;
  font-size: 14px;
  line-height: 51px;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  background-color: $brandColor;
  border-color: $brandColor;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-radius: 30px 30px 30px 30px
}

.tp-caption.Button-Outline-Secondary:hover,
.Button-Outline-Secondary:hover {
  color: $white;
  text-decoration: none;
  background-color: $brandColor;
  border-color: $brandColor;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-radius: 30px 30px 30px 30px;
  cursor: pointer
}

#rev_slider_11_2 .uranus.tparrows,
#rev_slider_11_1 .uranus.tparrows,
#custom-slider .uranus.tparrows {
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0);
  transform: translateY(-50%) !important;
  left: 30px !important;
  opacity: 0;
  transition: 0.4s;

  @media #{$sm-device} {

    left: 5px !important;
  }

  &.tp-rightarrow {
    left: auto !important;
    right: 30px;

    @media #{$sm-device} {
      right: 5px;
    }
  }
}

#rev_slider_11_2 .uranus.tparrows:before,
#rev_slider_11_1 .uranus.tparrows:before,
#custom-slider .uranus.tparrows:before {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 40px;
  transition: all 0.3s;
}

#rev_slider_11_2 .uranus.tparrows:hover:before,
#rev_slider_11_1 .uranus.tparrows:hover:before,
#custom-slider .uranus.tparrows:hover:before {
  opacity: 0.75
}

#slide-27-layer-7,
#slide-28-layer-3 {
  color: $brandColor;
}

#slide-28-layer-1,
#slide-28-layer-2,
#slide-29-layer-7 {
  color: $white;
}

#rev_slider_11_2 .uranus.tparrows,
#rev_slider_11_1 .uranus.tparrows,
#custom-slider .uranus.tparrows {

  @media #{$sm-device} {
    display:none !important;
  }

}

#rev_slider_11_2:hover .uranus.tparrows,
#rev_slider_11_1:hover .uranus.tparrows,
#custom-slider:hover .uranus.tparrows {
  opacity: 1;
}

#rev_slider_11_2 .rev-btn,
#rev_slider_11_1 .rev-btn {
  text-transform: uppercase;
}


#main-slider {

  background:#002f35;

  .slider {
    height:100%;
    position:relative;
    visibility: hidden;

    .slick-arrow {
      position: absolute;
      top: 50%;

      width:50px;
      height:50px;
      z-index:1000;
      line-height:50px;
      opacity:0;
      display:none !important;

      transition: opacity .2s;

      &:before {
        font-family: revicons;
        font-size: 40px;
        color: #fff;
        display: block;
        text-align: center;
      }

      @media screen and (min-width: 1400px) {
        display:block !important;
      }

      &.slick-prev {

        left:30px;

        &:before {
          content: '\e824';
        }
      }

      &.slick-next {

        right:30px;

        &:before {
          content: '\e825';
        }
      }
    }

    .slide-item {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      max-height:600px;
      height: 40vw;

      @media #{$sm-device} {
        height: 60vw;
      }

      .bg {
        content: "";
        display: block;
        width: 50%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(
            90deg
          ,#020913 0,rgba(2,9,19,0));
        pointer-events: none;
        z-index:0;
      }

      .container {
        position:relative;
        z-index:2;

        .slide-content {
          padding-top:12vw;
          -webkit-font-smoothing: antialiased;

          @media #{$sm-device} {
            padding-top:15vw;
          }

          @media screen and (min-width: 1200px) {
            padding-top:17.5%;

            .title {
              margin-left:-5px;
            }

            .subtitle {
              margin-left:-2px;
            }

          }

          .title {
            @include  fluid-type(320px, 1600px, 20px, 50px);
            //text-shadow: 1px 1px #000;
            font-weight: 600;
            line-height:1;
            margin-bottom:1rem;

            span {
              display:inline-block;

              opacity: 0;
              transform: translate3d(100%, 0, 0);
            }

          }

          .subtitle {
            font-weight: 400;
            @include  fluid-type(320px, 1600px, 20px, 28px);
            line-height:1;

            span {
              opacity: 0;
              transform: translate3d(0, 100%, 0);
            }

          }

          .button {

            margin-top:1.5rem;

            opacity: 0;
            transform: translate3d(0, 100%, 0);

            .btn {
              padding: 0px 40px;

              &:hover {
                background:#090b19;
              }
            }


          }


        }

      }

    }

    &:hover {
      .slick-arrow {
        opacity:1;
      }
    }

    &.is-loaded {

      visibility: visible;

      .title span {
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count:1;
        animation-name: fadeInRightTitle;
      }

      .slide-item.is-visible {
        //.title span {
        //  animation-duration: 1s;
        //  animation-fill-mode: both;
        //  animation-iteration-count:1;
        //  animation-name: fadeInUpTitle;
        //}
        .subtitle span {
          animation-duration: 1s;
          animation-delay: 0.2s;
          animation-fill-mode: both;
          animation-iteration-count:1;
          animation-name: fadeInUpTitle;
        }

        .button {
          animation-duration: 1s;
          animation-delay: 0.5s;
          animation-fill-mode: both;
          animation-iteration-count:1;
          animation-name: fadeInUpTitle;
        }

      }


    }



  }

}
