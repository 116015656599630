a.participatie-item {
  display: block;
  background: #FFF;
  padding: 20px;
  color: inherit;
  transition: box-shadow 0.3s ease-in-out;

  .participatie-logo {
    margin-bottom: 20px;
  }
  .participatie-info {
    color: inherit;
    h2 {
      //font-size: 16px;
      font-weight: bold;
    }
    p {
      //font-size: 12px;
    }
  }
  &:hover, &:focus {
    box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.25);
  }
}
