/*-----------------------------------------
[Table Of Content]

    01. Typography CSS
    02. Color CSS
    03. Common Style CSS
    04. Header Area Style CSS
        4.1 - Main Navigation CSS
    05. Off Canvas Style CSS
    06. Form Style CSS
    07. Slider Style CSS
    08. About Style CSS
    09. Icon Box Style CSS
    10. Service Style CSS
    11. Flip Box Style CSS
    12. Testimonial Style CSS
    13. Team Style CSS
    14. Blog Style CSS
    15. Brand Logo Style CSS
    16. Fun Fact Style CSS
    17. Page Header Style CSS
    18. About History Style CSS
    19. Pricing Table Style CSS
    19. Pricing Table Style CSS
    20. Accordion Style CSS
    21. FAQ Page Style CSS
    22. Service Details Page Style CSS
    23. Team Member Details Page Style CSS
    24. Skill Bar Style CSS
    25. Timeline Style CSS
    26. Blog Page Style CSS
    27. Blog Page Style CSS
    28. Contact Page Style CSS

    --. Footer Style CSS
----------------------------------------*/
@font-face {
  font-family: 'Museo Sans';
  src: url("/assets/fonts/MuseoSans-100.otf");
  font-weight: 100; }

@font-face {
  font-family: 'Museo Sans';
  src: url("/assets/fonts/MuseoSans-100Italic.otf");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'Museo Sans';
  src: url("/assets/fonts/MuseoSans-300.otf");
  font-weight: 300; }

@font-face {
  font-family: 'Museo Sans';
  src: url("/assets/fonts/MuseoSans-300Italic.otf");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Museo Sans';
  src: url("/assets/fonts/MuseoSans_500.otf");
  font-weight: 500; }

@font-face {
  font-family: 'Museo Sans';
  src: url("/assets/fonts/MuseoSans_500_Italic.otf");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Museo Sans';
  src: url("/assets/fonts/MuseoSans_700.otf");
  font-weight: 700; }

@font-face {
  font-family: 'Museo Sans';
  src: url("/assets/fonts/MuseoSans-700Italic.otf");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Museo Sans';
  src: url("/assets/fonts/MuseoSans_900.otf");
  font-weight: 900; }

@font-face {
  font-family: 'Museo Sans';
  src: url("/assets/fonts/MuseoSans-900Italic.otf");
  font-weight: 900;
  font-style: italic; }

/*=====================================
   01. Typography CSS
===================================== */
body {
  color: #002F35;
  font-size: 0.9375rem;
  font-family: "Museo Sans", Georgia;
  font-weight: 400;
  line-height: 1.6;
  margin: 0; }
  @media only screen and (max-width: 575.98px) {
    body {
      font-size: 1rem; } }

/* Remove text-shadow in selection highlight. */
::selection {
  background: #00A3DA;
  color: #FFFFFF;
  text-shadow: none; }

/* A better looking default horizontal rule */
hr {
  border: 0;
  border-top: 1px solid #eee;
  box-sizing: content-box;
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  overflow: visible; }

/* Remove the gap between audio, canvas, iframes,images, videos */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/* Remove default fieldset styles. */
fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

/* Allow only vertical resizing of textareas. */
textarea {
  resize: vertical; }

/* Anchor Tag Default Style */
a {
  transition: 0.4s;
  outline: none;
  text-decoration: none; }
  a:hover, a:active, a:focus {
    color: inherit;
    outline: none;
    text-decoration: none; }

textarea:focus, textarea:active, input:focus, input:active {
  outline: none; }

/* Heading Default Style */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 400;
  color: #002F35;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 25px; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.375rem + 1.5vw) ; } }

h2, .h2 {
  font-size: 2.25rem;
  font-weight: 600;
  margin-top: -5px;
  margin-bottom: 18px; }
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(1.35rem + 1.2vw) ; } }

h3, .h3 {
  font-size: 1.875rem;
  margin-bottom: 10px; }
  @media (max-width: 1200px) {
    h3, .h3 {
      font-size: calc(1.3125rem + 0.75vw) ; } }

h4, .h4 {
  font-size: 1.5rem; }
  @media (max-width: 1200px) {
    h4, .h4 {
      font-size: calc(1.275rem + 0.3vw) ; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

/* Paragraph Margin */
p {
  margin-bottom: 15px; }
  p:last-child {
    margin-bottom: 0; }

strong, b {
  font-weight: 700; }

figure {
  margin: 0; }

img {
  max-width: 100%; }

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  box-shadow: none;
  transition: 0.4s; }
  button:active, button:focus {
    outline: none;
    box-shadow: none; }

[data-mfp-src], .btn-img-popup {
  cursor: zoom-in; }

.parallax {
  background-repeat: no-repeat;
  background-size: cover; }

.form-message.alert {
  margin-bottom: 0;
  margin-top: 10px;
  position: absolute;
  width: 100%; }

/*==========================
02. Color CSS
============================*/
.bg-white {
  background-color: #FFFFFF; }

.bg-offwhite {
  background-color: #f8f9fc; }

.bg-brand {
  background-color: #00A3DA; }

.bg-blackSoft {
  background-color: #222222; }

.text-brand {
  color: #00A3DA; }

/*============================
03. Common Style CSS
==============================*/
/*-----------------
Overflow
-------------------*/
.fix {
  overflow: hidden; }
  .fix-x {
    overflow-x: hidden; }
  .fix-y {
    overflow-y: hidden; }

/*-----------------
Section Title
-------------------*/
.section-title {
  margin-bottom: 55px; }
  @media only screen and (max-width: 767.98px) {
    .section-title {
      margin-bottom: 40px; } }
  .section-title h6 {
    margin-bottom: 13px;
    font-weight: 400;
    text-transform: uppercase; }
  @media only screen and (max-width: 767.98px) {
    .section-title h2 {
      font-size: 24px; } }
  .section-title--light {
    color: #FFF; }
    .section-title--light h2, .section-title--light h6 {
      color: #FFFFFF; }

/*----------------------
Background Image
-----------------------*/
.bg-img {
  background: no-repeat center center;
  background-size: cover; }

/*----------------------
Container
-----------------------*/
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1200px; } }

.container-fluid {
  padding: 0 100px; }
  @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .container-fluid {
      padding: 0 15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
    .container-fluid {
      padding: 0 30px; } }
  @media only screen and (max-width: 479.98px) {
    .container-fluid {
      padding: 0 15px; } }

/*----------------------
Button Style
-----------------------*/
.btn-outline {
  border: 2px solid #00A3DA;
  border-radius: 100px;
  color: #002F35;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  padding: 18px 55px; }
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .btn-outline {
      padding: 18px 50px;
      font-size: 16px; } }
  @media only screen and (max-width: 767.98px) {
    .btn-outline {
      padding: 15px 40px;
      font-size: 15px; } }
  .btn-outline:hover {
    background-color: #00A3DA;
    color: #FFFFFF; }

/*----------------------
Slick Slider
-----------------------*/
.slick-list .slick-slide {
  border: 0 solid transparent !important; }
  .slick-list .slick-slide > div > div {
    vertical-align: middle; }

.slick-dots {
  display: flex;
  margin-top: 35px;
  justify-content: center;
  height: 12px; }
  @media only screen and (min-width: 992px) and (max-width: 1199.98px), only screen and (min-width: 1200px) {
    .slick-dots {
      justify-content: flex-start; } }
  .slick-dots li {
    margin-right: 10px; }
    .slick-dots li button {
      border: 2px solid #00A3DA;
      border-radius: 50%;
      text-indent: -100000px;
      height: 12px;
      width: 12px; }
    .slick-dots li.slick-active button {
      background-color: #00A3DA; }
  .slick-dots--light li button {
    border-color: #FFFFFF; }
  .slick-dots--light li.slick-active button {
    background-color: #FFFFFF; }

.slick-row-5 .slick-list {
  margin: 0 -2.5px; }
  .slick-row-5 .slick-list .slick-slide {
    margin: 0 2.5px; }

.slick-row-10 .slick-list {
  margin: 0 -5px; }
  .slick-row-10 .slick-list .slick-slide {
    margin: 0 5px; }

.slick-row-15 .slick-list {
  margin: 0 -7.5px; }
  .slick-row-15 .slick-list .slick-slide {
    margin: 0 7.5px; }

.slick-row-20 .slick-list {
  margin: 0 -10px; }
  .slick-row-20 .slick-list .slick-slide {
    margin: 0 10px; }

.slick-row-25 .slick-list {
  margin: 0 -12.5px; }
  .slick-row-25 .slick-list .slick-slide {
    margin: 0 12.5px; }

.slick-row-30 .slick-list {
  margin: 0 -15px; }
  .slick-row-30 .slick-list .slick-slide {
    margin: 0 15px; }

/*--------------------------------
Scroll Top Button
----------------------------------*/
.btn-scroll-top {
  background-color: #00A3DA;
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  cursor: pointer;
  font-size: 1.5625rem;
  display: block;
  text-align: center;
  line-height: 60px;
  position: fixed;
  bottom: -60px;
  right: 30px;
  height: 60px;
  width: 60px;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s; }
  @media (max-width: 1200px) {
    .btn-scroll-top {
      font-size: calc(1.28125rem + 0.375vw) ; } }
  @media only screen and (max-width: 767.98px) {
    .btn-scroll-top {
      line-height: 50px;
      height: 50px;
      width: 50px; } }
  .btn-scroll-top:hover {
    background-color: #182141; }
  .btn-scroll-top.show {
    bottom: 30px;
    opacity: 1;
    visibility: visible; }

/*--------------------------------
Blockquote Style
----------------------------------*/
blockquote, .blockquote {
  background-color: #f8f9fc;
  color: #002F35;
  font-size: 1.25rem;
  line-height: 1.4;
  padding: 42px 44px 80px;
  position: relative;
  margin-bottom: 20px; }
  @media only screen and (max-width: 767.98px) {
    blockquote, .blockquote {
      padding: 20px 22px 60px; } }
  blockquote:before, .blockquote:before {
    content: '\f10e';
    font-family: "FontAwesome";
    position: absolute;
    right: 40px;
    bottom: 40px; }
    @media only screen and (max-width: 767.98px) {
      blockquote:before, .blockquote:before {
        right: 30px;
        bottom: 20px; } }
  blockquote-title, .blockquote-title {
    color: #00A3DA;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 1.5px;
    line-height: 1; }

/*===========================
4.1 - Main Navigation CSS
=============================*/
.main-menu {
  align-content: center;
  justify-content: flex-end; }
  .main-menu > li {
    margin-right: 30px;
    position: relative;
    padding-bottom: 4px; }
    .main-menu > li:last-child {
      margin-right: 0; }
    .main-menu > li > a {
      color: #002F35;
      display: block;
      line-height: 1;
      letter-spacing: 1.62px;
      text-transform: uppercase;
      position: relative; }
      @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        .main-menu > li > a {
          font-size: 14px; } }
    .main-menu > li:hover {
      opacity: 0.7; }
    .main-menu > li.active {
      border-bottom: 2px solid #00A3DA; }
    .main-menu > li.has-submenu {
      margin-right: 37px;
      padding-right: 10px;
      position: relative; }
      .main-menu > li.has-submenu:after {
        color: #FFFFFF;
        content: "\f107";
        font-size: 16px;
        line-height: 1;
        font-family: "FontAwesome";
        position: absolute;
        right: -5px;
        top: 0; }
      .main-menu > li.has-submenu .submenu-nav {
        background-color: #FFFFFF;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        border-bottom: 2px solid #00A3DA;
        position: absolute;
        left: -25px;
        top: 100%;
        transform: translateY(50px);
        transition: 0.4s;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        min-width: 250px;
        margin-top: 40px;
        z-index: 99; }
        @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
          .main-menu > li.has-submenu .submenu-nav {
            min-width: 220px;
            margin-top: 38px; } }
        .main-menu > li.has-submenu .submenu-nav:before {
          content: "";
          position: absolute;
          height: 40px;
          width: 100%;
          left: 0;
          bottom: 100%; }
        .main-menu > li.has-submenu .submenu-nav > li > a {
          color: #002F35;
          display: block;
          font-size: 15px;
          letter-spacing: inherit;
          text-transform: capitalize;
          padding: 10px 20px; }
        .main-menu > li.has-submenu .submenu-nav > li:hover > a {
          background-color: #00A3DA;
          color: #FFFFFF; }
        .main-menu > li.has-submenu .submenu-nav-mega {
          display: flex;
          margin-left: -300px;
          padding: 0;
          width: 1080px; }
          @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
            .main-menu > li.has-submenu .submenu-nav-mega {
              width: 960px;
              margin-left: -245px; } }
          .main-menu > li.has-submenu .submenu-nav-mega .mega-menu-item {
            border-right: 1px solid #eee;
            padding: 30px;
            flex-basis: 25%; }
            .main-menu > li.has-submenu .submenu-nav-mega .mega-menu-item:last-child {
              border-right: 0; }
            .main-menu > li.has-submenu .submenu-nav-mega .mega-menu-item > a {
              display: none; }
            .main-menu > li.has-submenu .submenu-nav-mega .mega-menu-item ul li {
              margin-bottom: 10px; }
              .main-menu > li.has-submenu .submenu-nav-mega .mega-menu-item ul li:last-child {
                margin-bottom: 0; }
              .main-menu > li.has-submenu .submenu-nav-mega .mega-menu-item ul li a {
                color: #002F35;
                font-size: 15px;
                line-height: 1; }
                .main-menu > li.has-submenu .submenu-nav-mega .mega-menu-item ul li a:hover {
                  color: #00A3DA; }
      .main-menu > li.has-submenu:hover > .submenu-nav {
        transform: none;
        opacity: 1;
        visibility: visible;
        pointer-events: visible; }

/* Responsive Mobile Menu */
.res-mobile-menu {
  margin: 0 -10px; }
  .res-mobile-menu .slicknav_btn {
    display: none; }
  .res-mobile-menu .slicknav_menu {
    padding: 0; }
  .res-mobile-menu .slicknav_nav {
    background-color: #00A3DA;
    display: block !important;
    padding: 20px 30px; }
    .res-mobile-menu .slicknav_nav li {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      position: relative; }
      .res-mobile-menu .slicknav_nav li:last-child {
        border-bottom: 0; }
      .res-mobile-menu .slicknav_nav li a {
        color: #FFFFFF;
        font-size: 16px;
        padding: 12px 0;
        margin: 0;
        text-transform: capitalize;
        position: relative; }
        .res-mobile-menu .slicknav_nav li a .slicknav_arrow {
          background-color: rgba(255, 255, 255, 0.1);
          background-color: #00A3DA;
          color: #FFFFFF;
          font-size: 14px;
          display: block;
          text-align: center;
          margin: 0;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 35px;
          line-height: 35px;
          width: 35px; }
        .res-mobile-menu .slicknav_nav li a a {
          padding: 0; }
        .res-mobile-menu .slicknav_nav li a:hover {
          color: #FFFFFF;
          background-color: transparent; }
      .res-mobile-menu .slicknav_nav li img {
        display: none; }
      .res-mobile-menu .slicknav_nav li ul {
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0;
        padding-left: 10px; }
        .res-mobile-menu .slicknav_nav li ul li a {
          font-size: 13px;
          color: rgba(255, 255, 255, 0.7); }
    .res-mobile-menu .slicknav_nav .slicknav_open > .slicknav_item {
      position: relative; }
      .res-mobile-menu .slicknav_nav .slicknav_open > .slicknav_item .slicknav_arrow {
        background-color: #007da7; }

/*==========================
3.0 - Template Spacing
============================*/
.sp-top {
  padding-top: 110px; }
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .sp-top {
      padding-top: 90px; } }
  @media only screen and (max-width: 767.98px) {
    .sp-top {
      padding-top: 60px; } }

.sp-top-wt {
  padding-top: 105px; }
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .sp-top-wt {
      padding-top: 85px; } }
  @media only screen and (max-width: 767.98px) {
    .sp-top-wt {
      padding-top: 57px; } }

.sp-y {
  padding: 110px 0; }
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .sp-y {
      padding: 90px 0; } }
  @media only screen and (max-width: 767.98px) {
    .sp-y {
      padding: 60px 0; } }

.sm-top {
  margin-top: 110px; }
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .sm-top {
      margin-top: 90px; } }
  @media only screen and (max-width: 767.98px) {
    .sm-top {
      margin-top: 60px; } }

.sm-top-wt {
  margin-top: 105px; }
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .sm-top-wt {
      margin-top: 85px; } }
  @media only screen and (max-width: 767.98px) {
    .sm-top-wt {
      margin-top: 57px; } }

.sm-y {
  margin: 110px 0; }
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .sm-y {
      margin: 90px 0; } }
  @media only screen and (max-width: 767.98px) {
    .sm-y {
      margin: 60px 0; } }

/*==========================
04. Header Area Style CSS
===========================*/
.header-area {
  background-color: #00A3DA;
  background-color: #FFF;
  padding: 30px 0;
  transition: 0.4s; }
  .header-area.sticky {
    animation: slideInDown 0.6s forwards;
    padding: 5px 0 15px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99; }
    @media only screen and (max-width: 767.98px), only screen and (min-width: 768px) and (max-width: 991.98px) {
      .header-area.sticky {
        padding: 20px 0; } }
    .header-area.sticky .logo-dark {
      display: none; }
    .header-area.sticky .main-menu .submenu-nav {
      margin-top: 25px; }
      .header-area.sticky .main-menu .submenu-nav:before {
        height: 25px; }
  .header-area.transparent:not(.sticky) {
    background-color: transparent;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99; }
    .header-area.transparent:not(.sticky) .logo-light {
      display: none; }
    .header-area.transparent:not(.sticky) .main-menu {
      margin: 0;
      padding: 0; }
      .header-area.transparent:not(.sticky) .main-menu > li {
        list-style-type: none; }
        .header-area.transparent:not(.sticky) .main-menu > li.has-submenu:after {
          color: #00A3DA;
          font-weight: 600; }
        .header-area.transparent:not(.sticky) .main-menu > li > a {
          color: #00A3DA;
          font-weight: 600; }
    .header-area.transparent:not(.sticky) .header-action a, .header-area.transparent:not(.sticky) .header-action button {
      color: #00A3DA;
      font-weight: 600; }

.header-action {
  color: #FFFFFF;
  font-size: 22px; }
  @media only screen and (min-width: 992px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
    .header-action {
      font-size: 20px; } }
  .header-action a {
    color: #002F35; }
    @media only screen and (max-width: 575.98px) {
      .header-action a.tel-no {
        display: none; } }
  .header-action [class*='btn-'] {
    color: #002F35;
    margin-left: 20px; }

/*===========================
--. Footer Style CSS
===========================*/
.footer-area {
  background-color: #f8f9fc; }
  .footer-area address p {
    margin-bottom: 0; }

.widget-item {
  margin-top: 38px; }
  .widget-item .widget-title {
    color: #00A3DA;
    font-size: 22px;
    font-weight: 600;
    line-height: 1;
    margin-top: -1px;
    margin-bottom: 22px; }
    @media only screen and (max-width: 767.98px) {
      .widget-item .widget-title {
        margin-bottom: 15px; } }
  .widget-item address {
    line-height: 2;
    margin-bottom: 0;
    font-weight: 500; }

.widget-list {
  margin: 0;
  padding: 0; }
  .widget-list li {
    list-style-type: none;
    line-height: 2; }
    .widget-list li a {
      color: #002F35;
      font-weight: 500;
      transition: 0.2s; }
      .widget-list li a:hover {
        color: #00A3DA;
        padding-left: 5px; }

.about-widget img {
  max-width: 120px;
  margin-bottom: 20px; }

.copyright-txt {
  margin-top: 25px;
  font-weight: 500; }
  @media only screen and (max-width: 767.98px) {
    .copyright-txt {
      margin-top: 15px; } }

/*==========================
05. Off Canvas Style CSS
============================*/
.off-canvas-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  height: 100vh;
  width: 100vw;
  z-index: 9999; }
  .off-canvas-wrapper.active {
    opacity: 1;
    visibility: visible;
    pointer-events: visible; }
    .off-canvas-wrapper.active .off-canvas-inner {
      transform: none; }
    .off-canvas-wrapper.active .btn-close {
      display: block; }
    .off-canvas-wrapper.active .off-canvas-overlay {
      opacity: 1;
      visibility: visible; }
  .off-canvas-wrapper .close-btn {
    color: #00A3DA;
    font-size: 32px;
    line-height: 1;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    background: #FFFFFF;
    width: 100%;
    text-align: right;
    padding-right: 15px;
    padding-top: 10px; }
    .off-canvas-wrapper .close-btn .btn-close {
      margin-left: auto; }
    .off-canvas-wrapper .close-btn i {
      line-height: 1; }
    .off-canvas-wrapper .close-btn:hover {
      color: #00A3DA; }

.off-canvas-cog .off-canvas-content {
  padding-right: 30px;
  padding-left: 30px; }
  @media only screen and (max-width: 767.98px) {
    .off-canvas-cog .off-canvas-content {
      padding-right: 15px;
      padding-left: 15px; } }

.off-canvas-overlay {
  background-color: rgba(0, 47, 53, 0.8);
  cursor: url("../img/icons/cancel-white.png"), auto;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  height: 100%;
  width: 100%;
  z-index: 2; }

.off-canvas-inner {
  background-color: #FFFFFF;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: 0.45s;
  height: 100%;
  z-index: 3;
  overflow-y: auto; }

.off-canvas-content {
  width: 380px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 53px 0 80px;
  overflow-y: auto;
  overflow-x: hidden; }
  @media only screen and (max-width: 767.98px) {
    .off-canvas-content {
      padding: 53px 0 30px; } }
  @media only screen and (max-width: 575.98px) {
    .off-canvas-content {
      width: 280px; } }

.log-in-content-wrap h2 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 46px;
  margin-top: -7px; }

.log-in-content-wrap .sign-up-notification {
  text-align: center;
  margin-top: 15px;
  font-size: 15px; }
  .log-in-content-wrap .sign-up-notification a {
    color: #00A3DA;
    font-weight: 500; }

.social-icons a {
  font-size: 14px;
  color: #002F35;
  margin-right: 10px; }
  .social-icons a:last-child {
    margin-right: 0; }
  .social-icons a:hover {
    color: #00A3DA; }

.copyright-content {
  margin-top: 5px;
  font-size: 14px; }

/*=====================
06. Form Style CSS
====================== */
.form-input-item {
  margin-top: 20px; }
  .form-input-item input {
    background-color: #f8f9fc;
    border: none;
    display: block;
    font-size: 16px;
    padding: 15px 20px;
    width: 100%; }
  .form-input-item .btn-submit {
    background-color: #00A3DA;
    border: 2px solid #00A3DA;
    color: #FFFFFF;
    display: block;
    font-size: 15px;
    font-weight: 500;
    padding: 12px 20px;
    width: 100%; }
    .form-input-item .btn-submit:hover {
      color: #00A3DA;
      background-color: transparent; }
  .form-input-item--outline input {
    background-color: transparent;
    border: 2px solid #00A3DA; }
  .form-input-item--outline .btn-submit {
    background-color: transparent; }

/*========================
09. Icon Box Style CSS
=========================*/
.icon-box-item {
  display: flex; }
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .icon-box-item {
      display: block; } }
  @media only screen and (max-width: 767.98px) {
    .icon-box-item {
      margin-top: 40px; } }
  .icon-box-item .icon-box__icon {
    text-align: center;
    margin-right: 20px;
    flex-basis: 50px;
    width: 50px; }
    @media only screen and (min-width: 768px) and (max-width: 991.98px) {
      .icon-box-item .icon-box__icon {
        margin-right: 0;
        margin-bottom: 20px; } }
  .icon-box-item .icon-box__info {
    flex-basis: calc(100% - 50px);
    width: calc(100% - 50px); }
    @media only screen and (min-width: 1200px) {
      .icon-box-item .icon-box__info {
        flex-basis: 215px;
        width: 215px; } }
    @media only screen and (min-width: 768px) and (max-width: 991.98px) {
      .icon-box-item .icon-box__info {
        flex-basis: 100%;
        width: 100%; } }
    .icon-box-item .icon-box__info h5 {
      font-weight: 500;
      line-height: 1;
      margin-bottom: 10px; }

/*=========================
20. Accordion Style CSS
===========================*/
.brand-accordion .card {
  border: 1px solid #eee !important;
  border-radius: 0;
  margin-bottom: 15px;
  padding: 20px; }
  .brand-accordion .card:last-child {
    margin-bottom: 0; }
  .brand-accordion .card .card-header {
    background-color: transparent;
    padding: 0;
    border: none; }
    .brand-accordion .card .card-header button {
      font-weight: 600;
      padding: 0;
      transition: none;
      box-shadow: none;
      position: relative;
      width: 100%;
      text-align: left; }
      .brand-accordion .card .card-header button:after {
        content: "+";
        position: absolute;
        right: 0;
        font-size: 20px;
        color: #00A3DA;
        line-height: 1;
        top: 50%;
        transform: translateY(-50%); }
      .brand-accordion .card .card-header button[aria-expanded='true'] {
        color: #00A3DA;
        margin-bottom: 10px; }
        .brand-accordion .card .card-header button[aria-expanded='true']:after {
          content: "-"; }
  .brand-accordion .card .card-body {
    padding: 0;
    font-size: 13px;
    line-height: 2; }
    .brand-accordion .card .card-body p {
      margin-bottom: 0; }

/*===========================
24. Skill Bar Style CSS
============================*/
.single-skill-bar {
  margin-top: 30px; }
  .single-skill-bar .skills-info {
    margin-bottom: 20px; }
    .single-skill-bar .skills-info .skill-title, .single-skill-bar .skills-info .skill-percent {
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;
      margin: 0; }
    .single-skill-bar .skills-info .skill-percent {
      font-weight: 600; }
  .single-skill-bar .progress {
    background-color: #f8f9fc;
    border-radius: 0;
    height: 10px; }
    .single-skill-bar .progress .progress-bar {
      background-color: #00A3DA; }

/*========================
25. Timeline Style CSS
===========================*/
.cd-timeline-wrap {
  margin-top: 70px;
  position: relative; }
  .cd-timeline-wrap:before {
    border: 2px solid #00A3DA;
    border-radius: 50%;
    color: #00A3DA;
    content: "\f19d";
    font-family: "FontAwesome";
    font-size: 25px;
    height: 70px;
    left: 50%;
    line-height: 65px;
    position: absolute;
    text-align: center;
    top: -70px;
    transform: translateX(-50%);
    width: 70px; }
    @media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
      .cd-timeline-wrap:before {
        top: -50px;
        left: -5px;
        transform: none;
        line-height: 45px;
        height: 50px;
        width: 50px; } }
  .cd-timeline-wrap .cd-timeline {
    margin: 0;
    padding: 90px 0 100px; }
    @media only screen and (max-width: 767.98px) {
      .cd-timeline-wrap .cd-timeline {
        padding: 60px 0 70px; } }
    .cd-timeline-wrap .cd-timeline:before {
      background-color: #00A3DA; }
    .cd-timeline-wrap .cd-timeline:after {
      background-color: #00A3DA;
      border-radius: 50%;
      bottom: 0;
      content: "";
      height: 20px;
      left: 50%;
      margin-left: -10px;
      position: absolute;
      width: 20px; }
      @media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
        .cd-timeline-wrap .cd-timeline:after {
          left: 0;
          margin-left: 10px; } }
    .cd-timeline-wrap .cd-timeline-block {
      margin: 0 0 100px; }
      @media only screen and (max-width: 767.98px) {
        .cd-timeline-wrap .cd-timeline-block {
          margin: 0 0 30px; } }
      .cd-timeline-wrap .cd-timeline-block:last-child {
        margin: 0; }
      .cd-timeline-wrap .cd-timeline-block:nth-child(2n) .cd-timeline-content {
        text-align: left; }
    .cd-timeline-wrap .cd-timeline-img {
      background-color: #f8f9fc;
      border: 2px solid #00A3DA;
      -webkit-box-shadow: none;
      box-shadow: none;
      height: 35px;
      margin-left: -17.5px;
      top: 25px;
      width: 35px;
      z-index: 1; }
      @media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
        .cd-timeline-wrap .cd-timeline-img {
          margin-left: 3px; } }
      .cd-timeline-wrap .cd-timeline-img .dot {
        background-color: #00A3DA;
        border-radius: 50%;
        display: block;
        height: 10px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 10px; }
    .cd-timeline-wrap .cd-timeline-content {
      background-color: transparent;
      box-shadow: none;
      padding: 10px 0 0;
      text-align: right; }
      @media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
        .cd-timeline-wrap .cd-timeline-content {
          text-align: left; } }
      .cd-timeline-wrap .cd-timeline-content:before {
        display: none; }
      .cd-timeline-wrap .cd-timeline-content h5 {
        color: #00A3DA;
        font-weight: 600;
        margin-bottom: 5px;
        text-transform: uppercase; }
        @media only screen and (max-width: 767.98px) {
          .cd-timeline-wrap .cd-timeline-content h5 {
            font-size: 16px; } }
      .cd-timeline-wrap .cd-timeline-content p {
        margin: 0; }
        .cd-timeline-wrap .cd-timeline-content p span {
          font-weight: 600; }
      .cd-timeline-wrap .cd-timeline-content .cd-description {
        color: #002F35;
        font-size: 15px !important;
        opacity: 1 !important;
        padding: 0;
        text-align: left; }
  @media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
    .cd-timeline-wrap .cd-heading {
      margin-bottom: 10px; } }

a.participatie-item {
  display: block;
  background: #FFF;
  padding: 20px;
  color: inherit;
  transition: box-shadow 0.3s ease-in-out; }
  a.participatie-item .participatie-logo {
    margin-bottom: 20px; }
  a.participatie-item .participatie-info {
    color: inherit; }
    a.participatie-item .participatie-info h2 {
      font-weight: bold; }
  a.participatie-item:hover, a.participatie-item:focus {
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25); }

.header-action .btn-group .dropdown-toggle {
  cursor: pointer; }
  .header-action .btn-group .dropdown-toggle::before {
    font-family: "FontAwesome";
    content: "\f107";
    padding-right: 4px; }
  .header-action .btn-group .dropdown-toggle::after {
    display: none; }
  .header-action .btn-group .dropdown-toggle img {
    width: 20px; }

.header-action .btn-group .dropdown-menu {
  min-width: 0;
  padding: 0 10px;
  border: 0;
  margin: 0;
  width: calc(100% + 10px); }
  .header-action .btn-group .dropdown-menu .dropdown-item {
    padding: 10px 0;
    display: flex;
    justify-content: flex-end; }
    .header-action .btn-group .dropdown-menu .dropdown-item:hover {
      background: #FFFFFF; }
    .header-action .btn-group .dropdown-menu .dropdown-item img {
      width: 20px; }

.header-action .btn-group.show .dropdown-toggle::before {
  font-family: "FontAwesome";
  content: "\f106";
  padding-right: 4px; }

.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */ }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%; }

/*======================
21. FAQ Page Style CSS
=========================*/
.single-subject-by-faq-wrap {
  margin-top: 50px; }
  .single-subject-by-faq-wrap h2 {
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 38px; }
    @media (max-width: 1200px) {
      .single-subject-by-faq-wrap h2 {
        font-size: calc(1.325rem + 0.9vw) ; } }
    @media only screen and (min-width: 768px) and (max-width: 991.98px) {
      .single-subject-by-faq-wrap h2 {
        margin-bottom: 30px; } }
    @media only screen and (max-width: 767.98px) {
      .single-subject-by-faq-wrap h2 {
        margin-bottom: 25px; } }

/*==========================================
22. Service Details Page Style CSS
===========================================*/
.service-details-thumb .slick-dots {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%); }

.service-details-info {
  margin-top: 35px; }
  @media only screen and (max-width: 767.98px) {
    .service-details-info {
      margin-top: 30px; } }
  .service-details-info h3 {
    font-weight: 600;
    margin-bottom: 15px; }
  .service-details-info h4 {
    font-weight: 500;
    margin-bottom: 20px; }
  .service-details-info .service-feature li {
    position: relative;
    padding-left: 40px;
    margin-bottom: 15px; }
    .service-details-info .service-feature li:last-child {
      margin-bottom: 0; }
    .service-details-info .service-feature li:before {
      content: '\fc2e';
      font-family: "Material Design Icons";
      font-size: 30px;
      line-height: 1;
      position: absolute;
      top: 4px;
      left: 0; }

.how-we-works-content {
  margin-top: -15px; }
  .how-we-works-content .icon-box-item {
    align-items: flex-start;
    margin-top: 40px; }
    .how-we-works-content .icon-box-item .icon-box__info {
      width: 100%;
      flex-basis: auto; }

.pagination-wrap {
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-top: 55px; }
  @media only screen and (max-width: 767.98px) {
    .pagination-wrap {
      margin-top: 40px; } }
  .pagination-wrap .pagination li.prev {
    margin-right: auto; }
  .pagination-wrap .pagination li.next {
    margin-left: auto; }
  .pagination-wrap .pagination li a {
    color: #00A3DA;
    font-weight: 600;
    margin: 0 10px; }

.sidebar-single {
  background-color: #f8f9fc;
  padding: 30px;
  margin-top: 40px; }
  @media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
    .sidebar-single {
      margin-top: 30px; } }

.sidebar-heading {
  font-size: 26px;
  margin-bottom: 23px;
  font-weight: 500; }
  @media only screen and (max-width: 575.98px) {
    .sidebar-heading {
      font-size: 22px; } }

.service-list li {
  border-bottom: 1px solid #eee;
  padding: 13px 0; }
  .service-list li:first-child {
    padding-top: 0; }
  .service-list li:last-child {
    padding-bottom: 0;
    border-bottom: 0; }
  .service-list li a {
    color: #002F35;
    font-weight: 600;
    vertical-align: middle; }
    .service-list li a img, .service-list li a i {
      margin-right: 15px;
      vertical-align: middle;
      font-size: 25px;
      height: 25px;
      width: 25px; }
    .service-list li a:hover {
      color: #002F35;
      padding-left: 10px; }

@media only screen and (min-width: 1200px) {
  .service-details-wrapper .service-details-content {
    padding-right: 70px; } }

@media only screen and (min-width: 1200px) {
  .service-details-wrapper .sidebar-wrap {
    margin-left: -30px; } }

/*==========================================
23. Team Member Details Page Style CSS
===========================================*/
.member-desc h2 {
  font-size: 2rem;
  margin-bottom: 8px; }
  @media (max-width: 1200px) {
    .member-desc h2 {
      font-size: calc(1.325rem + 0.9vw) ; } }

.member-desc h5 {
  opacity: 0.8;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 25px; }

.member-social-icons {
  display: flex; }
  .member-social-icons a {
    color: #FFFFFF;
    display: block;
    font-size: 18px;
    text-align: center;
    line-height: 40px;
    height: 40px;
    width: 50px; }
    .member-social-icons a.facebook {
      background-color: #3B5999; }
    .member-social-icons a.twitter {
      background-color: #1DA1F2; }
    .member-social-icons a.linkedin {
      background-color: #0077B5; }
    .member-social-icons a.reddit {
      background-color: #FF4500; }
    .member-social-icons a.pinterest {
      background-color: #CB2028; }

.contact-info p {
  position: relative;
  padding-left: 85px; }
  .contact-info p strong {
    position: absolute;
    width: 75px;
    left: 0;
    top: 0; }
    .contact-info p strong:after {
      content: ':';
      right: 0;
      position: absolute;
      top: 0; }

.mem-achieve-item {
  margin-top: 50px; }
  .mem-achieve-item h4 {
    font-weight: 600; }
  @media only screen and (min-width: 1200px) {
    .mem-achieve-item .skill-bar-wrap, .mem-achieve-item .history-content-wrap {
      max-width: 75%; } }
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .mem-achieve-item .history-content-wrap .icon-box-item {
      display: flex; } }
  .mem-achieve-item .history-content-wrap .icon-box-item .icon-box__icon {
    margin-right: 20px;
    margin-bottom: 0;
    flex-basis: 100px;
    width: 100px; }
  .mem-achieve-item.member-education {
    margin-bottom: 55px; }
    @media only screen and (max-width: 767.98px) {
      .mem-achieve-item.member-education {
        margin-bottom: 40px; } }

/*===========================
26. Blog Page Style CSS
=============================*/
.blog-content-wrapper .blog-item .blog-content {
  padding-top: 35px;
  padding-bottom: 30px; }

.blog-content-wrapper.blog-list .blog-item .blog-content {
  padding-top: 25px;
  padding-bottom: 20px; }
  @media only screen and (min-width: 1200px), only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .blog-content-wrapper.blog-list .blog-item .blog-content {
      padding-left: 0; } }

.single-sidebar-item-wrap {
  margin-bottom: 50px; }
  .single-sidebar-item-wrap:last-child {
    margin-bottom: 0; }
  .single-sidebar-item-wrap .sidebar-title {
    font-size: 18px;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 30px;
    margin-top: -3px; }
    .single-sidebar-item-wrap .sidebar-title:before {
      background-color: #00A3DA;
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 3px;
      width: 30px; }

.sidebar-list li a {
  border: 1px solid #eee;
  border-bottom: 0;
  color: #222222;
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  padding: 20px; }
  .sidebar-list li a:hover {
    color: #00A3DA; }

.sidebar-list li:last-child a {
  border-bottom: 1px solid #eee; }

.sidebar-body .latest-blog-widget .single-blog-item {
  display: flex;
  margin-bottom: 20px; }
  .sidebar-body .latest-blog-widget .single-blog-item:last-child {
    margin-bottom: 0; }
  .sidebar-body .latest-blog-widget .single-blog-item .post-info {
    flex-basis: calc(100% - 75px); }
    .sidebar-body .latest-blog-widget .single-blog-item .post-info h6 {
      font-weight: 600;
      font-size: 14px; }
      .sidebar-body .latest-blog-widget .single-blog-item .post-info h6 a {
        color: #222222; }
        .sidebar-body .latest-blog-widget .single-blog-item .post-info h6 a:hover {
          padding-left: 0; }
        .sidebar-body .latest-blog-widget .single-blog-item .post-info h6 a:before {
          display: none; }
  .sidebar-body .latest-blog-widget .single-blog-item .post-thumb {
    max-width: 65px;
    flex-basis: 65px;
    max-height: 65px;
    overflow: hidden;
    margin-right: 10px; }

.sidebar-newsletter {
  background-color: #f8f9fc;
  padding: 56px 30px 60px; }
  @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .sidebar-newsletter {
      padding: 56px 10px 60px; } }
  .sidebar-newsletter h3 {
    color: #00A3DA;
    font-size: 26px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 26px; }
  .sidebar-newsletter form input {
    background-color: #eeeeee;
    font-weight: 500;
    border: none;
    display: block;
    outline: none;
    padding: 15px;
    width: 100%; }
    @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
      .sidebar-newsletter form input {
        padding: 15px 10px; } }
  .sidebar-newsletter form button {
    border-color: #00A3DA;
    font-weight: 600;
    width: 100%;
    padding: 10px 15px;
    margin-top: 15px;
    transition: 0.3s; }
    .sidebar-newsletter form button:hover {
      background-color: #00A3DA;
      color: #FFFFFF; }

.sidebar-body .instagram-feed .instagram-gallery {
  margin: -5px -2.5px 0; }
  .sidebar-body .instagram-feed .instagram-gallery .instagram-item {
    max-width: calc(33.333% - 5px);
    display: inline-block;
    margin: 5px 2.5px 0; }

.sidebar-body .instagram-feed .insta-follow {
  color: #002F35;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  margin-top: 29px;
  margin-bottom: 0; }
  .sidebar-body .instagram-feed .insta-follow a {
    color: #002F35;
    font-weight: 600; }
  .sidebar-body .instagram-feed .insta-follow i {
    margin-right: 5px; }

/*===================================
27. Blog Details Page Style CSS
===================================*/
.blog-post-details .blog-post-thumb {
  margin-bottom: 50px; }
  @media only screen and (max-width: 767.98px) {
    .blog-post-details .blog-post-thumb {
      margin-bottom: 40px; } }

.blog-post-details .blog-post-txt h2 {
  font-weight: 600;
  margin-bottom: 18px; }

.blog-post-details .blog-post-txt img {
  margin-bottom: 15px; }

.blog-post-details .share-article {
  margin-top: 50px; }
  .blog-post-details .share-article h6 {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase; }
  .blog-post-details .share-article .member-social-icons {
    border-top: 1px solid #eee;
    padding-top: 20px;
    margin-top: 20px; }

.blog-post-details .comment-area-wrapper .btn-outline {
  border-radius: 0;
  border-width: 1px; }

.author-info {
  border-top: 8px solid #f8f9fc;
  border-bottom: 1px solid #eee;
  padding: 20px 0; }
  .author-info .author-thumb {
    border-radius: 50%;
    overflow: hidden;
    height: 100px;
    width: 100px;
    margin: 0 auto 15px; }
  .author-info .author-txt {
    text-align: center; }
    .author-info .author-txt h5 {
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 0; }
      .author-info .author-txt h5 .designation {
        display: block;
        font-weight: 400;
        margin-top: 5px; }
    .author-info .author-txt .member-social-icons {
      justify-content: center;
      margin-top: 15px; }
      .author-info .author-txt .member-social-icons a {
        color: #002F35;
        display: inline-block;
        height: auto;
        width: auto;
        margin-right: 15px;
        line-height: 1; }
        .author-info .author-txt .member-social-icons a:last-child {
          margin-right: 0; }

/*============================
Start Comment Area Wrapper
============================*/
.comment-area-wrapper {
  margin-top: 55px; }
  .comment-area-wrapper h3 {
    font-size: 26px;
    margin-bottom: 30px; }
    @media only screen and (max-width: 767.98px) {
      .comment-area-wrapper h3 {
        margin-bottom: 25px; } }

.single-comment-wrap {
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 35px; }
  .single-comment-wrap:last-child {
    margin-bottom: 0; }
  .single-comment-wrap.comment-reply {
    margin-left: 50px; }
    @media only screen and (max-width: 767.98px) {
      .single-comment-wrap.comment-reply {
        margin-left: 20px; } }
  .single-comment-wrap .author-thumb {
    border-radius: 3px;
    margin-right: 15px;
    height: 70px;
    max-width: 70px;
    flex-basis: 70px;
    overflow: hidden; }
  .single-comment-wrap .comments-info {
    margin-top: -4px;
    flex-basis: calc(100% - 70px); }
  .single-comment-wrap .comment-footer {
    margin-top: 8px; }
    .single-comment-wrap .comment-footer a {
      font-size: 14px;
      color: #002F35; }
      .single-comment-wrap .comment-footer a strong {
        color: #222222; }
      .single-comment-wrap .comment-footer a.btn-reply {
        color: #222222; }
        .single-comment-wrap .comment-footer a.btn-reply:hover {
          color: #00A3DA; }

/*==========================================
28. Contact Page Style CSS
===========================================*/
.single-input-item {
  color: #002F35;
  margin-bottom: 30px; }
  .single-input-item label, .single-input-item .custom-control-label {
    color: #002F35;
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
    position: relative; }
    .single-input-item label.required:after, .single-input-item .custom-control-label.required:after {
      content: '*';
      color: red;
      margin-left: 2px; }
  .single-input-item input, .single-input-item textarea {
    border: 1px solid #eee;
    color: #002F35;
    font-weight: 500;
    display: block;
    outline: none;
    padding: 15px 20px;
    transition: 0.3s;
    width: 100%; }
    .single-input-item input:focus, .single-input-item textarea:focus {
      border-color: #00A3DA; }
  .single-input-item .nice-select {
    line-height: 30px;
    height: 50px;
    width: 100%; }
    .single-input-item .nice-select .current {
      color: #8d8d8d;
      font-weight: 400; }
    .single-input-item .nice-select .list {
      font-size: 14px; }

.contact-method h3 {
  font-weight: 600;
  margin-bottom: 43px; }

.contact-method.contact-form-area {
  padding: 45px 0 45px 30px; }
  @media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
    .contact-method.contact-form-area {
      padding: 45px 30px 45px 30px; } }

.contact-method.contact-information {
  background-color: #00A3DA;
  height: 100%;
  color: #FFFFFF;
  padding: 45px 30px;
  display: flex;
  align-items: center; }
  @media only screen and (min-width: 992px) and (max-width: 1199.98px), only screen and (min-width: 1200px) {
    .contact-method.contact-information {
      justify-content: center; } }
  .contact-method.contact-information h3 {
    color: #FFFFFF; }
  .contact-method.contact-information address p {
    margin-bottom: 0; }
  .contact-method.contact-information address a {
    color: #fff;
    text-decoration: underline; }
  .contact-method.contact-information .member-social-icons a {
    height: auto;
    width: auto;
    margin-right: 15px;
    opacity: 0.9; }
    .contact-method.contact-information .member-social-icons a:last-child {
      margin-right: 0; }

.contact-content-wrap {
  box-shadow: 0 10px 60px 0 rgba(71, 74, 182, 0.12); }

/*====================
07. Slider Style CSS
=======================*/
@keyframes fadeInRightTitle {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUpTitle {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.slider-area-wrapper {
  color: #FFFFFF; }

.tp-caption.Button-Outline-Secondary,
.Button-Outline-Secondary {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 51px;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  background-color: #00A3DA;
  border-color: #00A3DA;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-radius: 30px 30px 30px 30px; }

.tp-caption.Button-Outline-Secondary:hover,
.Button-Outline-Secondary:hover {
  color: #FFFFFF;
  text-decoration: none;
  background-color: #00A3DA;
  border-color: #00A3DA;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-radius: 30px 30px 30px 30px;
  cursor: pointer; }

#rev_slider_11_2 .uranus.tparrows,
#rev_slider_11_1 .uranus.tparrows,
#custom-slider .uranus.tparrows {
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0);
  transform: translateY(-50%) !important;
  left: 30px !important;
  opacity: 0;
  transition: 0.4s; }
  @media only screen and (max-width: 767.98px) {
    #rev_slider_11_2 .uranus.tparrows,
    #rev_slider_11_1 .uranus.tparrows,
    #custom-slider .uranus.tparrows {
      left: 5px !important; } }
  #rev_slider_11_2 .uranus.tparrows.tp-rightarrow,
  #rev_slider_11_1 .uranus.tparrows.tp-rightarrow,
  #custom-slider .uranus.tparrows.tp-rightarrow {
    left: auto !important;
    right: 30px; }
    @media only screen and (max-width: 767.98px) {
      #rev_slider_11_2 .uranus.tparrows.tp-rightarrow,
      #rev_slider_11_1 .uranus.tparrows.tp-rightarrow,
      #custom-slider .uranus.tparrows.tp-rightarrow {
        right: 5px; } }

#rev_slider_11_2 .uranus.tparrows:before,
#rev_slider_11_1 .uranus.tparrows:before,
#custom-slider .uranus.tparrows:before {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 40px;
  transition: all 0.3s; }

#rev_slider_11_2 .uranus.tparrows:hover:before,
#rev_slider_11_1 .uranus.tparrows:hover:before,
#custom-slider .uranus.tparrows:hover:before {
  opacity: 0.75; }

#slide-27-layer-7,
#slide-28-layer-3 {
  color: #00A3DA; }

#slide-28-layer-1,
#slide-28-layer-2,
#slide-29-layer-7 {
  color: #FFFFFF; }

@media only screen and (max-width: 767.98px) {
  #rev_slider_11_2 .uranus.tparrows,
  #rev_slider_11_1 .uranus.tparrows,
  #custom-slider .uranus.tparrows {
    display: none !important; } }

#rev_slider_11_2:hover .uranus.tparrows,
#rev_slider_11_1:hover .uranus.tparrows,
#custom-slider:hover .uranus.tparrows {
  opacity: 1; }

#rev_slider_11_2 .rev-btn,
#rev_slider_11_1 .rev-btn {
  text-transform: uppercase; }

#main-slider {
  background: #002f35; }
  #main-slider .slider {
    height: 100%;
    position: relative;
    visibility: hidden; }
    #main-slider .slider .slick-arrow {
      position: absolute;
      top: 50%;
      width: 50px;
      height: 50px;
      z-index: 1000;
      line-height: 50px;
      opacity: 0;
      display: none !important;
      transition: opacity .2s; }
      #main-slider .slider .slick-arrow:before {
        font-family: revicons;
        font-size: 40px;
        color: #fff;
        display: block;
        text-align: center; }
      @media screen and (min-width: 1400px) {
        #main-slider .slider .slick-arrow {
          display: block !important; } }
      #main-slider .slider .slick-arrow.slick-prev {
        left: 30px; }
        #main-slider .slider .slick-arrow.slick-prev:before {
          content: '\e824'; }
      #main-slider .slider .slick-arrow.slick-next {
        right: 30px; }
        #main-slider .slider .slick-arrow.slick-next:before {
          content: '\e825'; }
    #main-slider .slider .slide-item {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      max-height: 600px;
      height: 40vw; }
      @media only screen and (max-width: 767.98px) {
        #main-slider .slider .slide-item {
          height: 60vw; } }
      #main-slider .slider .slide-item .bg {
        content: "";
        display: block;
        width: 50%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(90deg, #020913 0, rgba(2, 9, 19, 0));
        pointer-events: none;
        z-index: 0; }
      #main-slider .slider .slide-item .container {
        position: relative;
        z-index: 2; }
        #main-slider .slider .slide-item .container .slide-content {
          padding-top: 12vw;
          -webkit-font-smoothing: antialiased; }
          @media only screen and (max-width: 767.98px) {
            #main-slider .slider .slide-item .container .slide-content {
              padding-top: 15vw; } }
          @media screen and (min-width: 1200px) {
            #main-slider .slider .slide-item .container .slide-content {
              padding-top: 17.5%; }
              #main-slider .slider .slide-item .container .slide-content .title {
                margin-left: -5px; }
              #main-slider .slider .slide-item .container .slide-content .subtitle {
                margin-left: -2px; } }
          #main-slider .slider .slide-item .container .slide-content .title {
            font-weight: 600;
            line-height: 1;
            margin-bottom: 1rem; }
            #main-slider .slider .slide-item .container .slide-content .title {
              font-size: 20px; }
              @media screen and (min-width: 320px) {
                #main-slider .slider .slide-item .container .slide-content .title {
                  font-size: calc(20px + 30 * ((100vw - 320px) / 1280)); } }
              @media screen and (min-width: 1600px) {
                #main-slider .slider .slide-item .container .slide-content .title {
                  font-size: 50px; } }
            #main-slider .slider .slide-item .container .slide-content .title span {
              display: inline-block;
              opacity: 0;
              transform: translate3d(100%, 0, 0); }
          #main-slider .slider .slide-item .container .slide-content .subtitle {
            font-weight: 400;
            line-height: 1; }
            #main-slider .slider .slide-item .container .slide-content .subtitle {
              font-size: 20px; }
              @media screen and (min-width: 320px) {
                #main-slider .slider .slide-item .container .slide-content .subtitle {
                  font-size: calc(20px + 8 * ((100vw - 320px) / 1280)); } }
              @media screen and (min-width: 1600px) {
                #main-slider .slider .slide-item .container .slide-content .subtitle {
                  font-size: 28px; } }
            #main-slider .slider .slide-item .container .slide-content .subtitle span {
              opacity: 0;
              transform: translate3d(0, 100%, 0); }
          #main-slider .slider .slide-item .container .slide-content .button {
            margin-top: 1.5rem;
            opacity: 0;
            transform: translate3d(0, 100%, 0); }
            #main-slider .slider .slide-item .container .slide-content .button .btn {
              padding: 0px 40px; }
              #main-slider .slider .slide-item .container .slide-content .button .btn:hover {
                background: #090b19; }
    #main-slider .slider:hover .slick-arrow {
      opacity: 1; }
    #main-slider .slider.is-loaded {
      visibility: visible; }
      #main-slider .slider.is-loaded .title span {
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        animation-name: fadeInRightTitle; }
      #main-slider .slider.is-loaded .slide-item.is-visible .subtitle span {
        animation-duration: 1s;
        animation-delay: 0.2s;
        animation-fill-mode: both;
        animation-iteration-count: 1;
        animation-name: fadeInUpTitle; }
      #main-slider .slider.is-loaded .slide-item.is-visible .button {
        animation-duration: 1s;
        animation-delay: 0.5s;
        animation-fill-mode: both;
        animation-iteration-count: 1;
        animation-name: fadeInUpTitle; }

/*==========================
08. About Style CSS
==========================*/
@media only screen and (min-width: 1200px) {
  .about-content {
    margin-left: 55px;
    padding-right: 80px; } }

.about-content h6 {
  margin-bottom: 13px; }

.about-content .about-since {
  color: #484848;
  display: inline-block;
  font-size: 24px;
  font-weight: 300;
  line-height: 1;
  position: relative;
  padding-right: 20px;
  margin-bottom: 30px; }
  @media only screen and (max-width: 767.98px) {
    .about-content .about-since {
      margin-bottom: 20px; } }
  .about-content .about-since:after {
    background-color: #b4b4b4;
    content: '';
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    width: 110px; }

.about-content .btn-about {
  color: #002F35;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  margin-top: 15px; }
  @media only screen and (max-width: 767.98px) {
    .about-content .btn-about {
      margin-top: 5px; } }
  .about-content .btn-about i {
    font-size: 20px;
    line-height: 3px;
    vertical-align: middle;
    transition: 0.3s; }
  .about-content .btn-about:hover i {
    padding-left: 5px; }

@media only screen and (min-width: 1200px) {
  .about-content--2 {
    margin-left: 0;
    padding-right: 40px; } }

.about-thumb {
  overflow: hidden; }
  @media only screen and (max-width: 767.98px) {
    .about-thumb {
      margin-bottom: 35px; } }
  .about-thumb img {
    width: 100%;
    transition: 0.4s; }
  .about-thumb--2 {
    margin-right: -250px; }

.home-two-about-area {
  background-color: #f8f9fc;
  background-image: url("../img/about-2-bg.jpg");
  background-position: center right;
  background-repeat: no-repeat; }
  @media only screen and (min-width: 992px) and (max-width: 1199.98px), only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    .home-two-about-area {
      background-size: 1100px auto; } }
  @media only screen and (max-width: 767.98px), only screen and (min-width: 768px) and (max-width: 991.98px) {
    .home-two-about-area {
      background-image: none; } }
  .home-two-about-area .about-content {
    padding-top: 100px;
    padding-bottom: 110px; }
    @media only screen and (min-width: 768px) and (max-width: 991.98px) {
      .home-two-about-area .about-content {
        padding-top: 40px;
        padding-bottom: 90px; } }
    @media only screen and (max-width: 767.98px) {
      .home-two-about-area .about-content {
        padding-bottom: 60px;
        padding-top: 0; } }

.video-button-thumb {
  position: relative; }
  .video-button-thumb:after {
    content: '';
    background-color: #00A3DA;
    opacity: 0.5;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .video-button-thumb .btn-play {
    border-radius: 50%;
    background-color: #FFFFFF;
    color: #00A3DA;
    font-size: 18px;
    text-align: center;
    line-height: 60px;
    height: 60px;
    width: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; }
    .video-button-thumb .btn-play:hover {
      background-color: #00A3DA;
      color: #FFFFFF; }
    .video-button-thumb .btn-play i {
      padding-left: 5px; }

/*======================
10. Service Style CSS
========================*/
.service-area-top {
  padding: 110px 0 130px;
  position: relative;
  z-index: 1; }
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .service-area-top {
      padding: 90px 0 135px; } }
  @media only screen and (max-width: 767.98px) {
    .service-area-top {
      padding: 60px 0 135px; } }
  .service-area-top:before {
    background-color: rgba(0, 163, 218, 0.9);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1; }

.service-content-area {
  margin-top: -170px;
  position: relative;
  z-index: 1; }

.service-item {
  margin-top: 30px;
  transition: 0.4s;
  position: relative;
  text-align: center;
  overflow: hidden; }
  .service-item .service-txt {
    background-color: #f8f9fc;
    padding: 25px 0; }
    .service-item .service-txt h5 {
      font-weight: 600;
      margin-bottom: 0; }
      @media only screen and (max-width: 767.98px) {
        .service-item .service-txt h5 {
          font-size: 16px; } }
  .service-item .service-content {
    background-color: rgba(0, 163, 218, 0.95);
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
    position: absolute;
    transition: 0.4s;
    left: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    pointer-events: none; }
    @media only screen and (max-width: 767.98px) {
      .service-item .service-content {
        padding: 15px; } }
    .service-item .service-content h5 {
      color: #FFFFFF;
      font-weight: 500;
      margin-bottom: 18px; }
      .service-item .service-content h5 a {
        color: #FFFFFF; }
  .service-item:hover {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.26); }
    .service-item:hover .service-content {
      left: 0; }

.non-opacity:after, .non-opacity:before {
  display: none; }

/*============================
12. Testimonial Style CSS
==============================*/
.testimonial-item {
  background-color: #FFFFFF;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.11);
  display: flex !important;
  align-items: center;
  padding: 40px;
  outline: none; }
  @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .testimonial-item {
      padding: 30px 20px; } }
  @media only screen and (max-width: 575.98px) {
    .testimonial-item {
      display: inline-block !important;
      padding: 30px 20px; } }
  .testimonial-item .testimonial-txt {
    flex-basis: calc(100% - 140px);
    max-width: calc(100% - 140px);
    font-weight: 400; }
    @media only screen and (max-width: 575.98px) {
      .testimonial-item .testimonial-txt {
        flex-basis: 100%;
        max-width: 100%;
        text-align: center; } }
    .testimonial-item .testimonial-txt img {
      margin-bottom: 20px; }
      @media only screen and (max-width: 575.98px) {
        .testimonial-item .testimonial-txt img {
          margin-left: auto;
          margin-right: auto; } }
    .testimonial-item .testimonial-txt .client-name {
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 0; }
      .testimonial-item .testimonial-txt .client-name .designation {
        font-size: 15px;
        font-weight: 400;
        color: #6a6a6a; }
  .testimonial-item .testimonial-thumb {
    border-radius: 50%;
    height: 110px;
    margin-left: 30px;
    flex-basis: 110px;
    max-width: 110px;
    overflow: hidden;
    order: 1; }
    @media only screen and (max-width: 575.98px) {
      .testimonial-item .testimonial-thumb {
        order: 0;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto; } }
  .testimonial-item--2 {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    display: block !important; }
    .testimonial-item--2 .testimonial-txt {
      font-size: 24px;
      font-weight: 300;
      max-width: 100%; }
      @media only screen and (min-width: 768px) and (max-width: 991.98px) {
        .testimonial-item--2 .testimonial-txt {
          font-size: 20px; } }
      @media only screen and (max-width: 767.98px) {
        .testimonial-item--2 .testimonial-txt {
          font-size: 18px; } }
      .testimonial-item--2 .testimonial-txt .client-name {
        margin-top: 25px; }
  .testimonial-item--3 {
    box-shadow: none; }

.testimonial-thumbnail-item {
  position: relative;
  z-index: 1; }
  .testimonial-thumbnail-item:after {
    border: 15px solid #f3f4f6;
    border-bottom: 0;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 85%;
    width: 100%;
    z-index: -1; }
  .testimonial-thumbnail-item:before {
    background-image: url("../img/testimonial/bg-dot.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 85%;
    width: 100%;
    z-index: -1; }

.testimonial-arrows-2 {
  text-align: right;
  margin-top: 20px; }
  @media only screen and (max-width: 767.98px) {
    .testimonial-arrows-2 {
      text-align: center; } }
  .testimonial-arrows-2 button {
    margin-left: 15px;
    color: #9f9f9f;
    transition: 0.5s;
    font-size: 18px;
    line-height: 1; }
    .testimonial-arrows-2 button:first-child {
      margin-left: 0; }
    .testimonial-arrows-2 button:hover {
      color: #00A3DA; }

@media only screen and (min-width: 1200px) {
  .testimonial-area .section-title {
    padding-right: 50px; } }

.testimonial-area .testimonial-content-wrap {
  margin-right: -5px; }
  @media only screen and (min-width: 1200px) {
    .testimonial-area .testimonial-content-wrap {
      padding-left: 40px; } }
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .testimonial-area .testimonial-content-wrap {
      margin-top: 50px; } }
  @media only screen and (max-width: 767.98px) {
    .testimonial-area .testimonial-content-wrap {
      margin-top: 35px; } }

@media only screen and (min-width: 1200px) {
  .testimonial-area .testimonial-area-right {
    padding-left: 60px; } }

.testimonial-area--2 {
  padding-top: 90px; }
  @media only screen and (max-width: 767.98px) {
    .testimonial-area--2 {
      padding: 60px 0; } }

.testimonial-content .slick-list {
  margin: -15px 0;
  padding-left: 5px;
  padding-right: 5px; }
  .testimonial-content .slick-list .slick-slide {
    margin: 15px 0; }

.tagline {
  font-size: 18px;
  line-height: 1; }
  .tagline strong {
    font-weight: 500; }
  .tagline .tag-no {
    font-weight: 600;
    position: relative; }
    .tagline .tag-no:after {
      background-color: #a2a2a4;
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      height: 2px;
      width: 100%; }

.testimonial-grid .slick-list {
  margin: 0 -10px; }
  .testimonial-grid .slick-list .slick-slide {
    padding: 0 10px; }

.testimonial-content--3 .slick-dots {
  justify-content: center; }

/*=======================
13. Team Style CSS
=========================*/
.team-mem-item {
  transition: 0.4s; }
  .team-mem-item .member-info {
    text-align: center;
    background-color: #FFFFFF;
    padding: 15px 10px; }
    .team-mem-item .member-info h5 {
      font-weight: 600;
      font-size: 18px;
      line-height: 1; }
      .team-mem-item .member-info h5 a {
        color: #002F35; }
        .team-mem-item .member-info h5 a:hover {
          color: #182141; }
    .team-mem-item .member-info .designation {
      font-weight: 400;
      color: #00A3DA; }
  .team-mem-item .member-pic img {
    width: 100%; }
  .team-mem-item--2 {
    position: relative;
    margin-top: 40px; }
    .team-mem-item--2 .member-info {
      background-color: transparent;
      padding: 0;
      position: absolute;
      top: -15px;
      left: -110px;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition: 0.4s;
      transform: translateY(-20px); }
      @media only screen and (max-width: 767.98px) {
        .team-mem-item--2 .member-info {
          left: -80px; } }
      .team-mem-item--2 .member-info:after {
        background-image: url("../img/team/team-arrow.png");
        background-repeat: no-repeat;
        background-position: center center;
        content: '';
        position: absolute;
        transform: translateY(-10px);
        transition: 0.4s;
        transition-duration: 0.8s;
        top: 100%;
        right: -15px;
        height: 40px;
        width: 100%; }
    .team-mem-item--2 .member-pic {
      text-align: center; }
      .team-mem-item--2 .member-pic img {
        max-height: 400px;
        width: auto;
        margin: auto; }
    .team-mem-item--2:hover .member-info {
      opacity: 1;
      visibility: visible;
      transform: none; }
      .team-mem-item--2:hover .member-info:after {
        transform: none; }

.team-area-wrapper {
  background-color: #002F35; }
  @media screen and (min-width: 1500px) {
    .team-area-wrapper .section-title {
      padding-right: 100px; } }
  @media screen and (min-width: 1500px) {
    .team-area-wrapper .team-content-wrap {
      padding-left: 45px; } }
  .team-area-wrapper .team-content-wrap .slick-dots {
    justify-content: center; }
  .team-area-wrapper .team-content-wrap .slick-list {
    margin: 0 -15px; }
    @media only screen and (max-width: 767.98px) {
      .team-area-wrapper .team-content-wrap .slick-list {
        margin: 0 -10px; } }
    .team-area-wrapper .team-content-wrap .slick-list .slick-slide {
      margin: 0 15px; }
      @media only screen and (max-width: 767.98px) {
        .team-area-wrapper .team-content-wrap .slick-list .slick-slide {
          margin: 0 10px; } }

.team-area-left {
  background-color: #00A3DA;
  padding: 110px 100px 105px;
  height: 100%; }
  @media only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    .team-area-left {
      padding: 90px 25px; } }
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .team-area-left {
      padding: 90px; } }
  @media only screen and (max-width: 767.98px) {
    .team-area-left {
      padding: 60px 30px  50px; } }
  @media only screen and (max-width: 575.98px) {
    .team-area-left {
      padding: 60px 15px 50px; } }

.team-area-right {
  position: relative;
  padding: 110px 100px 0; }
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .team-area-right {
      padding: 80px 100px 0; } }
  @media only screen and (max-width: 767.98px) {
    .team-area-right {
      padding: 50px 30px 0; } }

.team-page-area-wrapper .team-mem-item {
  margin-top: 30px;
  transition: 0.4s; }
  .team-page-area-wrapper .team-mem-item:hover {
    transform: translateY(-10px); }

/*=====================
14. Blog Style CSS
======================*/
.blog-item {
  background-color: #f8f9fc;
  transition: 0.4s;
  height: 100%; }
  .blog-item .blog-content {
    padding: 50px 30px 45px; }
    @media only screen and (max-width: 767.98px) {
      .blog-item .blog-content {
        padding: 30px 15px 25px; } }
    .blog-item .blog-content h2 {
      font-weight: 600;
      margin-bottom: 15px; }
      .blog-item .blog-content h2 a {
        color: #002F35; }
        .blog-item .blog-content h2 a:hover {
          color: #182141; }
    .blog-item .blog-content .blog-meta {
      display: flex;
      justify-content: space-between;
      margin-top: 20px; }
      .blog-item .blog-content .blog-meta a {
        color: #002F35;
        font-weight: 400;
        margin-right: 10px; }
        .blog-item .blog-content .blog-meta a:last-child {
          margin-right: 0; }
        @media only screen and (max-width: 575.98px) {
          .blog-item .blog-content .blog-meta a {
            font-size: 14px; } }
  .blog-item:hover {
    box-shadow: 0 10px 60px 0 rgba(71, 74, 182, 0.16);
    transform: translateY(-10px); }

/*============================
5. Brand Logo Style CSS
=============================*/
.brand-logo-item img {
  margin: auto; }

/*========================
16. Fun Fact Style CSS
==========================*/
.counter-item {
  margin-top: 40px; }

.counter-number {
  color: #f8f9fc;
  margin-bottom: 10px;
  position: relative;
  font-size: 24px;
  font-weight: 600; }
  @media only screen and (max-width: 767.98px) {
    .counter-number {
      font-size: 20px; } }
  .counter-number.plus:after {
    content: '+';
    position: absolute;
    padding-left: 5px; }

.counter-txt {
  color: #f8f9fc;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0; }
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .counter-txt {
      font-size: 20px; } }
  @media only screen and (max-width: 767.98px) {
    .counter-txt {
      font-size: 16px; } }

.fun-fact-area {
  padding: 80px 0 75px;
  position: relative; }
  .fun-fact-area:before {
    background-color: rgba(0, 163, 218, 0.92);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%; }

/*===========================
17. Page Header Style CSS
=============================*/
.page-header-area .page-header-content-inner {
  min-height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  padding: 30px 0; }
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .page-header-area .page-header-content-inner {
      min-height: 240px; } }
  @media only screen and (max-width: 767.98px) {
    .page-header-area .page-header-content-inner {
      min-height: 180px; } }
  .page-header-area .page-header-content-inner h2,
  .page-header-area .page-header-content-inner .h2 {
    color: #FFFFFF;
    margin-bottom: 0;
    font-size: 3.4375rem; }
    @media (max-width: 1200px) {
      .page-header-area .page-header-content-inner h2,
      .page-header-area .page-header-content-inner .h2 {
        font-size: calc(1.46875rem + 2.625vw) ; } }
    @media only screen and (max-width: 575.98px) {
      .page-header-area .page-header-content-inner h2,
      .page-header-area .page-header-content-inner .h2 {
        font-size: 25px; } }
  @media only screen and (min-width: 1200px) {
    .page-header-area .page-header-content-inner p {
      max-width: 60%;
      margin: auto; } }

.breadcrumb-wrap {
  margin-top: 25px; }
  @media only screen and (max-width: 767.98px) {
    .breadcrumb-wrap {
      margin-top: 15px; } }
  .breadcrumb-wrap .breadcrumb {
    background-color: transparent;
    border-radius: 0;
    justify-content: center;
    padding: 0;
    margin: 0; }
    .breadcrumb-wrap .breadcrumb li:last-child a:after {
      display: none; }
    .breadcrumb-wrap .breadcrumb li a {
      color: #FFFFFF;
      font-weight: 500;
      font-size: 14px;
      line-height: 1;
      position: relative; }
      .breadcrumb-wrap .breadcrumb li a:after {
        content: "-";
        margin: 0 20px;
        pointer-events: none; }
        @media only screen and (max-width: 767.98px) {
          .breadcrumb-wrap .breadcrumb li a:after {
            margin: 0 10px; } }
      .breadcrumb-wrap .breadcrumb li a.current {
        pointer-events: none;
        opacity: 0.8; }

/*============================
18. About History Style CSS
=============================*/
.history-item {
  margin-top: 40px; }
  @media only screen and (min-width: 1200px) {
    .history-item {
      padding-right: 20px; } }
  .history-item h6 {
    font-weight: 700;
    color: #00A3DA; }
  .history-item h3 {
    font-size: 22px;
    font-weight: 500; }

/*==============================
19. Pricing Table Style CSS
================================*/
.pricing-plan-buttons ul li a {
  background-color: transparent;
  border: 2px solid #00A3DA;
  color: #002F35;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  padding: 13px 40px;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  @media only screen and (max-width: 767.98px) {
    .pricing-plan-buttons ul li a {
      padding: 10px 20px; } }
  .pricing-plan-buttons ul li a:before {
    background-color: #00A3DA;
    content: '';
    position: absolute;
    left: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1; }
  .pricing-plan-buttons ul li a.active {
    color: #FFFFFF; }
    .pricing-plan-buttons ul li a.active:before {
      transition: 0.3s;
      left: 0; }

.pricing-plan-item {
  text-align: center;
  background-color: #f8f9fc;
  padding: 60px 25px;
  transition: 0.3s;
  margin-top: 30px; }
  .pricing-plan-item .plan-name {
    font-weight: 600;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1; }
  .pricing-plan-item .plan-price {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 30px 0; }
    @media only screen and (max-width: 767.98px) {
      .pricing-plan-item .plan-price {
        margin: 15px 0; } }
    .pricing-plan-item .plan-price .dollar {
      font-weight: 600;
      font-size: 24px;
      line-height: 1; }
    .pricing-plan-item .plan-price .price {
      font-weight: 700;
      font-size: 50px;
      line-height: 1;
      padding-right: 3px; }
    .pricing-plan-item .plan-price .price-type {
      font-weight: 500; }
    .pricing-plan-item .plan-price .dollar, .pricing-plan-item .plan-price .price-type {
      margin-bottom: 8px;
      display: block; }
  .pricing-plan-item .price-list li {
    margin-bottom: 10px;
    font-weight: 500; }
    .pricing-plan-item .price-list li:last-child {
      margin-bottom: 0; }
    .pricing-plan-item .price-list li:before {
      content: '\f5e1';
      font-family: "Material Design Icons";
      margin-right: 5px; }
  .pricing-plan-item .pricing-plan-footer {
    margin-top: 50px; }
    @media only screen and (max-width: 767.98px) {
      .pricing-plan-item .pricing-plan-footer {
        margin-top: 30px; } }
    .pricing-plan-item .pricing-plan-footer .btn-outline {
      border-radius: 6px;
      font-size: 14px;
      text-transform: uppercase; }
      @media only screen and (min-width: 992px) and (max-width: 1199.98px), only screen and (min-width: 1200px) {
        .pricing-plan-item .pricing-plan-footer .btn-outline {
          padding-left: 40px;
          padding-right: 40px; } }
  .pricing-plan-item:hover {
    transform: translateY(-10px); }

body.realestate .fun-fact-area:before {
  background: orange; }

body.realestate .widget-item .widget-title {
  color: orange; }

a, .btn-link {
  color: #00a3da; }

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: #00a3da; }
