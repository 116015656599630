// Overlay Mixin
@mixin overlay($bg: $black, $alpha: 0.5) {
  background-color: rgba($bg, $alpha);
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

// Element Hide Mixin
@mixin hide() {
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}

// Element Show Mixin
@mixin show() {
  opacity: 1;
  visibility: visible;
}

// Element Center(X,Y) Mixin
@mixin center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Element Horizontal Center(X) Mixin
@mixin centerX() {
  display: flex;
  justify-content: center;
}

// Element Vertical Center(Y) Mixin
@mixin centerY() {
  display: flex;
  align-items: center;
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}
