.header-action {
  .btn-group {
    .dropdown-toggle {
      cursor: pointer;

      &::before {
        font-family: "FontAwesome";
        content: "\f107";
        padding-right: 4px;
      }

      &::after {
        display:none;
      }

      img {
        width: 20px;
      }
    }

    .dropdown-menu {
      min-width: 0;
      padding: 0 10px;
      border: 0;
      margin: 0;
      width: calc(100% + 10px);

      .dropdown-item {
        padding: 10px 0;
        display: flex;
        justify-content: flex-end;
        
        &:hover {
          background: $white;
        }
        
        img {
          width: 20px;
        }
      }
    }

    &.show {
      .dropdown-toggle::before {
        font-family: "FontAwesome";
        content: "\f106";
        padding-right: 4px;
      }
    }
  }
}